import Stripe from 'stripe';
import {
  Basket,
  IvAtype,
  ShippingApiData,
  UserProfileBillingData,
  UserProfileShoppingAddress,
} from '../../../graphqlTypes';

export type CartStep = 'zero' | 'one' | 'two' | 'three';

export type CartLink = '/cart' | '/ebook-cart';

export type PaymentType = 'stripe' | 'paypal' | 'contrareembolso' | 'accountCode';

export type CartSendType = 'unico' | 'multiple';

export interface AmountVAT {
  discount: number;
  subtotal: number;
  total: number;
  group?: IvAtype[];
  basketWithTaxes?: BasketWithTaxes[];
}

export interface AmountOptions {
  address: UserProfileShoppingAddress;
  addressBilling: UserProfileBillingData;
  basket: Basket[];
  observations: string;
  payment: PaymentType;
  shipping: ShippingOptions;
}

export interface ShippingOptions {
  index: number;
  optionsShipping: ShippingApiData;
}

export interface TotalAndSubTotal {
  totalDiscount: number;
  total: number;
  subtotal: number;
  basketWithTaxes?: BasketWithTaxes[];
}

export interface TotalAndSubTotalDiscount extends Omit<TotalAndSubTotal, 'totalDiscount'> {
  discount: number;
}

export interface CartFeedback {
  ok: boolean;
  msg: string;
  classState?: string;
}

export interface UserAddressData {
  index: number;
  address: UserProfileShoppingAddress;
}

export interface UserBillingAddressData {
  index: number;
  address: UserProfileBillingData;
}

export interface PackageOrderSize {
  weight: number;
  length: number;
  width: number;
  height: number;
  volumetricWeight: number;
}

export interface AddressDHL {
  address: string;
  country: string;
  countryCode: string;
  postalCode: string;
  province: string;
}

export interface StripeSessionBody {
  setup: boolean;
  line_items: Stripe.Checkout.SessionCreateParams.LineItem[];
  off_session: boolean;
  email: string;
  address: string;
  shippingAmount: number;
  orderId: string;
  customer: string;
  isEbookCart: boolean;
  total: number;
}

export interface BasketWithTaxes extends Basket {
  discount?: number;
  subtotal?: number;
  total?: number;
}

export enum ZonePlusPackEuropeData {
  zoneOne = 'zoneOne',
  zoneTwo = 'zoneTwo',
  zoneThree = 'zoneThree',
}

export type ZonePlusPackEurope =
  | ZonePlusPackEuropeData.zoneOne
  | ZonePlusPackEuropeData.zoneTwo
  | ZonePlusPackEuropeData.zoneThree;
