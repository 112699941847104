import { isClient, roundToTwo } from '../../../utils/utils';
import {
  codeCountriesUE,
  postalCodes,
  provinces,
  insularPortugal,
  PlusPackEurope,
} from '../models/constants.cart';
import {
  AddressDHL,
  BasketWithTaxes,
  CartLink,
  ZonePlusPackEurope,
  ZonePlusPackEuropeData,
} from '../models/model.cart';
import {
  Basket,
  Coupon,
  IvAtypeNew,
  Link,
  NewAddress,
  OrderDeliveryAddress,
  OrderItemsNew,
  ShippingApiData,
  User,
  UserProfileShoppingAddress,
} from '../../../graphqlTypes';
import ZIP_PORTUGAL_ISLANDS from '../models/zipPortugalIsland';
import { CartState } from '../../../reducer/cart/cart.state';

export function existNoBook(basket: Basket[]): boolean {
  const books = basket.filter((x) => x.book.product_type !== 'book');
  if (books.length > 0) {
    return true;
  }
  return false;
}

/**
 * Function verify if the range of postal code is valid
 * @param postalCodeNumber
 * @param province
 * @returns
 */
export const getValidPostalCode = (postalCodeNumber: number, province: string): boolean => {
  if (
    postalCodeNumber >= postalCodes[province].min &&
    postalCodeNumber <= postalCodes[province].max
  ) {
    return true;
  }
  return false;
};

/**
 * Function confirm if the iso code of country not belong to Europe
 * @param newAddress
 * @returns
 */
export const isOutUE = (newAddress: NewAddress): boolean => {
  const isEuropeanCountry = codeCountriesUE.find((code) => code === newAddress.countryCode);
  return !isEuropeanCountry;
};

export const isNIFRequired = (postalCode: string | number): boolean => {
  const postalCodeNumber = Number(postalCode);
  if (!Number.isNaN(postalCodeNumber)) {
    if (
      getValidPostalCode(postalCodeNumber, 'melilla') ||
      getValidPostalCode(postalCodeNumber, 'ceuta') ||
      getValidPostalCode(postalCodeNumber, 'palmas') ||
      getValidPostalCode(postalCodeNumber, 'tenerife')
    ) {
      return true;
    }
  }
  return false;
};

export function existIVA(shipping: UserProfileShoppingAddress | OrderDeliveryAddress): boolean {
  if (!shipping) {
    return true;
  }
  let isAddresOutUE = false;
  if (Object.prototype.hasOwnProperty.call(shipping, 'isOutUE')) {
    isAddresOutUE = shipping.isOutUE;
  } else {
    isAddresOutUE = isOutUE(shipping);
  }
  if (
    shipping &&
    // (shipping.country !== 'España' ||   #Se hace el cambio para que haya iva en los paises comunitarios #925
    (isAddresOutUE ||
      provinces.findIndex((x) => {
        if (x && shipping && shipping.province) {
          return x.toLocaleLowerCase() === shipping.province.toLocaleLowerCase();
        }
        return false;
      }) > -1)
  ) {
    return false;
  }
  // Comprobación del IVA
  if (!isAddresOutUE && shipping.postalCode) {
    const postalCodeNumber = Number(shipping.postalCode);
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(postalCodeNumber)) {
      if (
        getValidPostalCode(postalCodeNumber, 'melilla') ||
        getValidPostalCode(postalCodeNumber, 'ceuta') ||
        getValidPostalCode(postalCodeNumber, 'palmas') ||
        getValidPostalCode(postalCodeNumber, 'tenerife')
      ) {
        return false;
      }
    }
  }

  return true;
}

export function getTotalAndSubtotal(
  basket: Basket[],
  coupon: Coupon,
  shipping?: UserProfileShoppingAddress,
  noDiscount?: boolean,
): {
  total: number;
  subtotal: number;
  totalDiscount: number;
  basketWithTaxes: BasketWithTaxes[];
} {
  const applyIva = existIVA(shipping);
  let subtotal = 0;
  let total = 0;
  let totalDiscount = 0;
  let fixedDiscount = coupon?.discount || coupon?.value || 0;
  let changeDiscount = false;
  const today = new Date();
  let totalBasketWithoutIvaIntial = 0;
  const basketWithTaxes: BasketWithTaxes[] = [];
  const totalItems = basket.length;

  for (let i = 0; i < totalItems; i += 1) {
    const currentProduct = basket[i];
    const product: BasketWithTaxes = { ...currentProduct };

    const { book } = currentProduct;
    if (book) {
      if (noDiscount && fixedDiscount) {
        changeDiscount = true;
        fixedDiscount = 0;
      }

      let saldoYOferta = [];
      if (book?.tags?.length) {
        saldoYOferta = book.tags.filter(
          (x) => x.category === 'availability' && x.items.includes('Saldos y Ofertas'),
        );
      }

      const applyDiscount =
        coupon &&
        saldoYOferta.length === 0 &&
        coupon.productType &&
        coupon.productType.findIndex((x) => x === book.product_type) !== -1 &&
        coupon.discount >=
          (book &&
          book.prices &&
          book.prices.saleSpecialDiscount !== undefined &&
          book.prices.ssdFrom &&
          book.prices.ssdTo &&
          today >= new Date(book.prices.ssdFrom) &&
          today < new Date(book.prices.ssdTo)
            ? book.prices.saleSpecialDiscount * 100
            : 0);

      const vat = book.vat ? book.vat.sale : 0;
      const vatR = 1 + vat / 100;
      const price =
        (!fixedDiscount || (fixedDiscount && !applyDiscount)) && !changeDiscount
          ? book.priceWithDiscount
          : book?.prices?.sale;
      let priceWithoutVAT = price * currentProduct.units;

      priceWithoutVAT = roundToTwo(priceWithoutVAT);

      if (applyDiscount) {
        totalBasketWithoutIvaIntial += priceWithoutVAT;
      }

      if (saldoYOferta.length === 0) {
        if (coupon && coupon.discount && !noDiscount && applyDiscount) {
          const discount = (priceWithoutVAT * coupon.discount) / 100;
          totalDiscount += discount;
          priceWithoutVAT -= discount;
          product.discount = roundToTwo(discount);
        } else if (fixedDiscount && applyDiscount) {
          totalDiscount = fixedDiscount;
          product.discount = roundToTwo(fixedDiscount);
          priceWithoutVAT -= (totalDiscount * priceWithoutVAT) / totalBasketWithoutIvaIntial;
        }
      }

      priceWithoutVAT /= vatR;
      const vatP = (priceWithoutVAT * vat) / 100;

      const totalPriceVat = priceWithoutVAT + vatP;
      subtotal += priceWithoutVAT;
      total += totalPriceVat;
      product.subtotal = roundToTwo(priceWithoutVAT);
      product.total = !applyIva ? roundToTwo(priceWithoutVAT) : roundToTwo(totalPriceVat);
      basketWithTaxes.push(product);
    }
  }

  if (!applyIva) {
    total = subtotal;
  }

  return {
    total: roundToTwo(total),
    subtotal: roundToTwo(subtotal),
    totalDiscount: roundToTwo(totalDiscount),
    basketWithTaxes,
  };
}

export function getItems(
  isPartial: boolean,
  basket: Basket[],
  totalAmount: number,
  coupon: Coupon,
  isEbookCart = false,
): { itemsF: OrderItemsNew[]; total: number } {
  const itemsF = [];

  let total = 0;
  if (isPartial) {
    let itemsSub = basket;
    if (!isEbookCart) {
      itemsSub = basket.filter((x) => x.book.availability.OK && x.book.stock_available >= x.units);
    }
    if (itemsSub) {
      for (let i = 0; i < itemsSub.length; i += 1) {
        const newItem = {
          _id: itemsSub[i]._id,
          ean: itemsSub[i].ean,
          title: itemsSub[i].book.title,
          units: itemsSub[i].units,
        };
        itemsF.push(newItem);
      }
      const totalAndSubtotal = getTotalAndSubtotal(itemsSub, coupon);
      const { total: totalSub } = totalAndSubtotal;
      total = totalSub;
      return { itemsF, total };
    }
  } else {
    total = totalAmount;

    for (let i = 0; i < basket.length; i += 1) {
      const newItem = {
        _id: basket[i]._id,
        ean: basket[i].ean,
        title: basket[i].book.title,
        units: basket[i].units,
      };
      itemsF.push(newItem);
    }
    return { itemsF, total };
  }
  return null;
}

export function analyzeBasket(basket: Basket[], state: boolean): number | boolean {
  if (basket) {
    const noStock = basket.filter(
      (x) => !x.book?.availability?.OK || x.book?.stock_available < x.units,
    );

    if (!state) {
      if (noStock.length === basket.length) {
        return 1;
      }
      if (noStock.length === 0) {
        return 2;
      }
    }
    if (state && noStock.length) {
      return true;
    }
  }
  return false;
}

export const calculateTotalWithTaxes = (
  basket: Basket[],
  amountTotal: number,
  shipping: ShippingApiData,
  coupon?: Coupon,
): { isPartial: boolean; total: number; shippingTotal: number } => {
  const isPartial = analyzeBasket(basket, true);
  const transport = shipping?.fee || 0;

  const { total } = getItems(true, basket, amountTotal, coupon);

  let totalParsed = Math.round(total * 100) / 100;
  const transportParsed = Math.round(transport * 100) / 100;
  totalParsed += transportParsed;
  totalParsed = Math.round(totalParsed * 100) / 100;

  return { isPartial: Boolean(isPartial), total: totalParsed, shippingTotal: transportParsed };
};

export const getUserCheck = (cartState: CartState, user: User): boolean => {
  if (cartState.mandatoryNIF) {
    const nif = user && user.profile && user.profile.NIF;
    return nif !== null && nif !== undefined && nif !== '';
  }
  return !!(cartState.userCheck || user);
};

/**
 * Function iterate the Basket for calculate total units
 * @param basket
 * @returns
 */
export const getTotalUnitsInBasket = (basket: Basket[] = []): number => {
  if (!basket.length) {
    return null;
  }

  const totalUnits = basket.reduce((numItems, { units }) => {
    return numItems + units;
  }, 0);

  return totalUnits;
};

export function isDHL(newAddress: AddressDHL): boolean {
  // Los envíos a las islas de Portugal son un caso ESPECIAL
  const zipPortugal = ZIP_PORTUGAL_ISLANDS;

  const found = zipPortugal.find(
    (e) =>
      e === newAddress.postalCode ||
      e.replace('-', '') === newAddress.postalCode ||
      e.split('-')[0] === newAddress.postalCode,
  );

  const parsedProvince = newAddress?.province?.trim().toLocaleLowerCase();

  const province = insularPortugal.find((p) => parsedProvince?.includes(p));

  if (
    (newAddress.countryCode &&
      newAddress.countryCode !== 'ES' &&
      newAddress.countryCode !== 'PT') ||
    (newAddress.countryCode === 'PT' && found) ||
    (newAddress.countryCode === 'PT' && province)
  ) {
    return true;
  }
  return false;
}

export function isPlusPackEurope(newAddress: AddressDHL): ZonePlusPackEurope {
  const { zoneOne, zoneTwo, zoneThree } = PlusPackEurope;
  const inZoneOne = zoneOne.countries.includes(newAddress.countryCode);
  if (inZoneOne) {
    return ZonePlusPackEuropeData.zoneOne;
  }
  const inZoneTwo = zoneTwo.countries.includes(newAddress.countryCode);
  if (inZoneTwo) {
    return ZonePlusPackEuropeData.zoneTwo;
  }

  const inZoneThree = zoneThree.countries.includes(newAddress.countryCode);
  if (inZoneThree) {
    return ZonePlusPackEuropeData.zoneThree;
  }

  return null;
}

export const parseDhlMessagesError = (dhlError: string): string => {
  const defaultMsg = 'Por favor, revise que la dirección introducida es correcta.';

  if (dhlError?.includes('Postcode not found')) {
    const [, format] = dhlError.split('(')[0].split('- ');
    return `Código postal erróneo. Revise que el código postal cumple el formato ${format}`;
  }

  if (dhlError?.includes('The destination location is invalid')) {
    return defaultMsg;
  }

  return defaultMsg;
};

export const getPaypalLink = (links: Link[]): string => {
  let newHref: string;
  for (let i = 0; i < links.length; i += 1) {
    const link = links[i];
    if (link.method === 'REDIRECT') {
      const index = link.href.indexOf('token');
      newHref = `${link.href.slice(0, index)}useraction=commit&${link.href.slice(index)}`;
    }
  }
  return newHref;
};

/**
 * Function returns type cart of user "Ebook or normal products"
 * @param currentUserBasket
 * @returns
 */
export const getTypeCartLink = (currentUserBasket: Basket[] = []): CartLink => {
  let routeCart: CartLink = '/cart';

  const storageBasket: Basket[] = currentUserBasket?.length
    ? currentUserBasket
    : JSON.parse(isClient() && localStorage.getItem('basket'));

  if (storageBasket?.length) {
    routeCart = storageBasket[0].book?.product_type === 'ebook' ? '/ebook-cart' : '/cart';
  }

  return routeCart;
};

export function getGroupVAT(
  basket: Basket[],
  coupon: Coupon,
  shipping: UserProfileShoppingAddress,
): IvAtypeNew[] {
  if (!existIVA(shipping)) {
    return [];
  }
  const group = [];
  let totalDiscount = 0;
  const fixedDiscount = coupon?.discount || coupon?.value || 0;
  let totalBasketWithoutIvaIntial = 0;
  const thisMoment: number = Date.parse(new Date().toISOString());

  for (let i = 0; i < basket.length; i += 1) {
    const currentProduct = basket[i];
    const { book } = currentProduct;
    if (book) {
      // const applyDiscount =
      //   coupon &&
      //   coupon.productType &&
      //   coupon.productType.findIndex(x => x === book.product_type) !== -1;
      const dateFrom = book.prices && book.prices.ssdFrom ? Date.parse(book.prices.ssdFrom) : 0;
      const dateTo = book.prices && book.prices.ssdTo ? Date.parse(book.prices.ssdTo) : 0;

      const applyDiscount =
        coupon &&
        coupon.productType &&
        coupon.productType.findIndex((x) => x === book.product_type) !== -1 &&
        coupon.discount >=
          (book &&
            book.prices &&
            book.prices.saleSpecialDiscount &&
            dateFrom < Number(thisMoment) &&
            Number(thisMoment) > dateTo &&
            book.prices.saleSpecialDiscount * 100);

      // const vat = book.vat ? book.vat.sale : 0;
      // const vatR = 1 + vat / 100;
      const price =
        !fixedDiscount || (fixedDiscount && !applyDiscount)
          ? book.priceWithDiscount
          : book.prices.sale;
      const priceWithoutVAT = price * currentProduct.units;
      if (applyDiscount) {
        totalBasketWithoutIvaIntial += roundToTwo(priceWithoutVAT);
      }
    }
  }

  for (let i = 0; i < basket.length; i += 1) {
    const currentProduct = basket[i];
    const { book } = currentProduct;
    if (book) {
      // const applyDiscount =
      //   coupon &&
      //   coupon.productType &&
      //   coupon.productType.findIndex(x => x === book.product_type) !== -1;
      const dateFrom = book.prices && book.prices.ssdFrom ? Date.parse(book.prices.ssdFrom) : 0;
      const dateTo = book.prices && book.prices.ssdTo ? Date.parse(book.prices.ssdTo) : 0;
      const applyDiscount =
        coupon &&
        coupon.productType &&
        coupon.productType.findIndex((x) => x === book.product_type) !== -1 &&
        coupon.discount >=
          (book &&
            book.prices &&
            book.prices.saleSpecialDiscount &&
            dateFrom < thisMoment &&
            thisMoment > dateTo &&
            book.prices.saleSpecialDiscount * 100);
      const vat = book.vat ? book.vat.sale : 0;
      const vatR = 1 + vat / 100;
      const index = group.map((q) => q.vat).indexOf(vat);
      const price =
        !fixedDiscount || (fixedDiscount && !applyDiscount)
          ? book.priceWithDiscount
          : book.prices.sale;
      let priceWithoutVAT = price * currentProduct.units;

      priceWithoutVAT = roundToTwo(priceWithoutVAT);
      if (coupon && coupon.discount && applyDiscount) {
        const discount = (priceWithoutVAT * coupon.discount) / 100;
        totalDiscount += discount;
        priceWithoutVAT -= discount;
      } else if (coupon && coupon.value && applyDiscount) {
        totalDiscount = fixedDiscount;
        priceWithoutVAT -= (totalDiscount * priceWithoutVAT) / totalBasketWithoutIvaIntial;
      }

      priceWithoutVAT /= vatR;

      if (index > -1) {
        group[index].total += priceWithoutVAT * (vat / 100);
      } else {
        const total = priceWithoutVAT * (vat / 100);
        group.push({ vat, total });
      }
    }
  }
  return group;
}
