import React from 'react';

interface ComponentProps {
  children?: React.ReactElement | React.ReactElement[];
  propsToChildren?: Record<string, unknown>;
  className?: string;
  id?: string;
  noPage?: boolean;
  reload?: boolean;
}

const PageContent: React.FC<ComponentProps> = ({
  children,
  className: classes,
  id,
  noPage,
  propsToChildren,
}: ComponentProps) => {
  return (
    <section
      id={id}
      className={`${noPage ? 'noPage' : 'pageContent'} ${classes || ''}`}
    >
      {propsToChildren?.mainLayout
        ? React.Children.map(children, (child): JSX.Element => {
            if (child) {
              return React.cloneElement(child, propsToChildren);
            }
            return null;
          })
        : children}
    </section>
  );
};

export default PageContent;
