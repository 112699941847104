export const provinces = ['CANARIAS', 'CEUTA', 'MELILLA', 'SANTA CRUZ DE TENERIFE', 'LAS PALMAS'];

export const postalCodes = {
  palmas: {
    min: 35001,
    max: 35660,
  },
  tenerife: {
    min: 38001,
    max: 38917,
  },
  melilla: {
    min: 52001,
    max: 52006,
  },
  ceuta: {
    min: 51001,
    max: 51005,
  },
};

export const insularPortugal = ['madeira', 'azores', 'Açores', 'asores', 'azorez', 'asorez'];

export const codeCountriesUE = [
  'BE',
  'BG',
  'CZ',
  'DK',
  'DE',
  'EE',
  'IE',
  'EL',
  'ES',
  'FR',
  'HR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
];

export const PlusPackEurope = {
  zoneOne: {
    countries: ['FR'],
    costWeight: {
      '1': 14.15,
      '2': 15.48,
      '5': 19.49,
      '10': 25.67,
      '15': 35.13,
      '20': 42.92,
    },
  },
  zoneTwo: {
    countries: ['DE', 'BE', 'IT', 'LU', 'NL'],
    costWeight: {
      '1': 17.5,
      '2': 19.44,
      '5': 24.21,
      '10': 31.06,
      '15': 40.82,
      '20': 47.51,
    },
  },
  zoneThree: {
    countries: ['AT', 'DK', 'GB'],
    costWeight: {
      '1': 22.6,
      '2': 23.41,
      '5': 29.32,
      '10': 36.74,
      '15': 47.35,
      '20': 56.67,
    },
  },
};

export type CountryCodeEuroBusinessParcel =
  | 'AL'
  | 'DE'
  | 'AT'
  | 'BE'
  | 'BG'
  | 'CY'
  | 'HR'
  | 'DK'
  | 'SK'
  | 'SI'
  | 'EE'
  | 'FI'
  | 'FR'
  | 'GR'
  | 'HU'
  | 'IE'
  | 'IT'
  | 'LV'
  | 'LT'
  | 'LU'
  | 'MT'
  | 'NL'
  | 'PL'
  | 'CZ'
  | 'RO'
  | 'SE';

export interface TaxInfoEuroBusinessParcel {
  '2.9999': number;
  '3': number;
  '5': number;
  '7': number;
  '10': number;
  '15': number;
  '20': number;
  '30': number;
  '40': number;
}

export type InfoEuroBusinessParcel = Record<
  CountryCodeEuroBusinessParcel,
  TaxInfoEuroBusinessParcel
>;

export const EuroBusinessParcel: InfoEuroBusinessParcel = {
  AL: {
    '2.9999': 43.52,
    '3': 52.36,
    '5': 62.98,
    '7': 73.59,
    '10': 91.28,
    '15': 123.13,
    '20': 151.43,
    '30': 204.5,
    '40': 266.42,
  },
  DE: {
    '2.9999': 7,
    '3': 7,
    '5': 7,
    '7': 7,
    '10': 7,
    '15': 7,
    '20': 7,
    '30': 7,
    '40': 7,
  },
  AT: {
    '2.9999': 7,
    '3': 7,
    '5': 7,
    '7': 7,
    '10': 7,
    '15': 7,
    '20': 7,
    '30': 7,
    '40': 7,
  },
  BE: {
    '2.9999': 7,
    '3': 7,
    '5': 7,
    '7': 7,
    '10': 7,
    '15': 7,
    '20': 7,
    '30': 7,
    '40': 7,
  },
  BG: {
    '2.9999': 24.06,
    '3': 30.25,
    '5': 32.02,
    '7': 33.79,
    '10': 36.44,
    '15': 39.98,
    '20': 43.52,
    '30': 54.13,
    '40': 68.28,
  },
  CY: {
    '2.9999': 32.02,
    '3': 45.29,
    '5': 62.09,
    '7': 78.9,
    '10': 105.43,
    '15': 149.66,
    '20': 192.12,
    '30': 280.57,
    '40': 370.79,
  },
  HR: {
    '2.9999': 24.06,
    '3': 30.25,
    '5': 32.02,
    '7': 33.79,
    '10': 36.44,
    '15': 39.98,
    '20': 43.52,
    '30': 54.13,
    '40': 68.28,
  },
  DK: {
    '2.9999': 7,
    '3': 7,
    '5': 7,
    '7': 7,
    '10': 7,
    '15': 7,
    '20': 7,
    '30': 7,
    '40': 7,
  },
  SK: {
    '2.9999': 7,
    '3': 15,
    '5': 15,
    '7': 15,
    '10': 15,
    '15': 15,
    '20': 15,
    '30': 15,
    '40': 15,
  },
  SI: {
    '2.9999': 7,
    '3': 15,
    '5': 15,
    '7': 15,
    '10': 15,
    '15': 15,
    '20': 15,
    '30': 15,
    '40': 15,
  },
  EE: {
    '2.9999': 24.06,
    '3': 30.25,
    '5': 32.02,
    '7': 33.79,
    '10': 36.44,
    '15': 39.98,
    '20': 43.52,
    '30': 54.13,
    '40': 68.28,
  },
  FI: {
    '2.9999': 24.06,
    '3': 30.25,
    '5': 32.02,
    '7': 33.79,
    '10': 36.44,
    '15': 39.98,
    '20': 43.52,
    '30': 54.13,
    '40': 68.28,
  },
  FR: {
    '2.9999': 7,
    '3': 7,
    '5': 7,
    '7': 7,
    '10': 7,
    '15': 7,
    '20': 7,
    '30': 7,
    '40': 7,
  },
  GR: {
    '2.9999': 25.83,
    '3': 32.9,
    '5': 39.98,
    '7': 50.59,
    '10': 64.75,
    '15': 88.63,
    '20': 114.28,
    '30': 162.04,
    '40': 211.58,
  },
  HU: {
    '2.9999': 24.06,
    '3': 39.98,
    '5': 45.64,
    '7': 46.35,
    '10': 48.47,
    '15': 49.36,
    '20': 50.24,
    '30': 21.41,
    '40': 71.47,
  },
  IE: {
    '2.9999': 24.06,
    '3': 30.25,
    '5': 32.02,
    '7': 33.79,
    '10': 36.44,
    '15': 39.98,
    '20': 43.52,
    '30': 54.13,
    '40': 68.28,
  },
  IT: {
    '2.9999': 7,
    '3': 7,
    '5': 7,
    '7': 7,
    '10': 7,
    '15': 7,
    '20': 7,
    '30': 7,
    '40': 7,
  },
  LV: {
    '2.9999': 24.06,
    '3': 30.25,
    '5': 32.02,
    '7': 33.79,
    '10': 36.44,
    '15': 39.98,
    '20': 43.52,
    '30': 54.13,
    '40': 68.28,
  },
  LT: {
    '2.9999': 24.06,
    '3': 30.25,
    '5': 32.02,
    '7': 33.79,
    '10': 36.44,
    '15': 39.98,
    '20': 43.52,
    '30': 54.13,
    '40': 68.28,
  },
  LU: {
    '2.9999': 7,
    '3': 7,
    '5': 7,
    '7': 7,
    '10': 7,
    '15': 7,
    '20': 7,
    '30': 7,
    '40': 7,
  },
  MT: {
    '2.9999': 25.83,
    '3': 36.44,
    '5': 45.64,
    '7': 55.9,
    '10': 70.05,
    '15': 98.36,
    '20': 121.36,
    '30': 167.35,
    '40': 216.88,
  },
  NL: {
    '2.9999': 7,
    '3': 7,
    '5': 7,
    '7': 7,
    '10': 7,
    '15': 7,
    '20': 7,
    '30': 7,
    '40': 7,
  },
  PL: {
    '2.9999': 7,
    '3': 7,
    '5': 7,
    '7': 7,
    '10': 7,
    '15': 7,
    '20': 7,
    '30': 7,
    '40': 7,
  },
  CZ: {
    '2.9999': 7,
    '3': 7,
    '5': 7,
    '7': 7,
    '10': 7,
    '15': 7,
    '20': 7,
    '30': 7,
    '40': 7,
  },
  RO: {
    '2.9999': 24.06,
    '3': 30.25,
    '5': 32.02,
    '7': 33.79,
    '10': 36.44,
    '15': 39.98,
    '20': 43.52,
    '30': 54.13,
    '40': 68.28,
  },
  SE: {
    '2.9999': 24.06,
    '3': 30.25,
    '5': 32.02,
    '7': 33.79,
    '10': 36.44,
    '15': 39.98,
    '20': 43.52,
    '30': 54.13,
    '40': 68.28,
  },
};
